:root {
    --tringulo-width: 140px;
}

/* MAIN SECTION */
.main-container {
    position: relative;
    background-image: url("../../img/background/blue-waves-lg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.banner{
    width: 90%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0px auto;
}

@media(min-width:1536px){
    .main-container{
        padding-bottom: 430px;
    }

    .banner{
        width: 85%;
    }
}

.mint-btn:hover{
    background-color: #5F7EFC;
    color: white;
    transition: all .5s;
    border: 1px solid black;
    box-shadow: 10px 10px 1px black;
}

/* FAQS */
.faqs-container{
    background-image: url("../../img/background/green-waves.png");
}

/* ROADMAP SECTION */

.roadmap-container{
    background-image: url("../../img/background/orange-waves.png");
}


.info-box-title,
.team-picture {
    width: 240px;
    top: -55px;
}

.ouroadmap-title {
    width: 170px;
    text-align: center;
    top: -100px;
}

/* TEAM SECTION */
.team-container{
    background-size: 300% 100%;
    background-repeat: no-repeat;
    background-image: url("../../img/background/blue-waves.png");
}

.team-picture {
    top: -100px;
    width: 180px;
}

.team-member {
    width: 51%;
}

@media(min-width:640px) {
    .team-container{
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../../img/background/blue-waves.png");
    }
    .team-member {
        width: 30%;
    }
}

@media(min-width:900px) {
    .team-member {
        width: 18%;
    }
}

@media(min-width:1280px) {
    .team {
        width: 90%;
        margin: auto;
        justify-content: space-around;
    }

    .team-member {
        width: 18%;
    }
}

@media(min-width:1536px) {
    .team {
        width: 65%;
        margin: auto;
        justify-content: space-around;
    }

    .team-member {
        width: 18%;
    }
}

/* DECORATIONS */

.decor{
    position: absolute;
    width: 100px;
}

.decor1{
    width: 120px;
    left: -50px;
    top: -50px;
}

.decor2{
    right: 10%;
    top: 25%;
}

.decor3{
    right: 20%;
    bottom:15%;
}

.decor4{
    right: -50px;
    top:-50px;
}

.decor5{
    left: 3%;
    top: 0%;
}

.decor6{
    top: 5%;
    right: 10%;
}

.decor7{
    width: 120px;
    bottom: -25px;
    left: -60px;
}

.faqs-decor{
    right:30px;
    bottom: -10px;
}

.roadmap-decor{
    width: 150px;
    bottom: 10px;
}

.roadmap-decor2{
    width: 120px;
    left: 8%;
    top: 50px;
}

@media(min-width:640px){
    .decor3{
        width: 150px;
    }

    .decor5{
        top:5%;
        left: 15%;
    }

    .decor6{
        width: 120px;
        top: 8%;
        right: 5%;
    }   
}


@media(min-width:768px){
    .decor2{
        top: 20%;
        right: 25%;
    }
    .decor3{
        width: 200px;
        bottom: 25%;
        right: 3%;
    }

    .decor5{
        width: 150px;
        left: 2%;
        top: 0px;
    }
}

@media(min-width:1280px){
    .decor2{
        width: 125px;
    }
    .decor3{
        bottom: 35%;
    }
    .decor5{
        top: 30%;
    }
}