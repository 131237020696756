@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
@font-face {
    font-family: "Title";
    font-display: swap;
    src: url("../fonts/cabold-comic/Cabold\ Comic\ demo.otf") format("truetype");
}

@font-face {
    font-family: "Text";
    font-display: swap;
    src: url("../fonts/Boogaloo/Boogaloo-Regular.ttf") format("truetype");
}


:root {
    /*COLORS*/
    --primary: #638EEB;
    --secondary: #63EBB4;
    --light: #67F5E4;

    /* AUXILIARS */
    --aux1: #BF99EB;
    --aux2: #BA7FD4;
    --aux3: #F094F6;
    --aux4: #BA7FD4;
    --aux5: #937FD4;

    --font-title: "Title";
    --font-title2: 'Luckiest Guy', cursive;
    --font-text: "Text";
}

.text-primary {
    color: var(--primary);
}

.text-secondary {
    color: var(--secondary);
}

.text-light {
    color: var(--light);
}


.bg-primary{
    background-color: var(--primary);
}

.bg-secondary{
    background-color: var(--secondary);
}

.bg-aux1 {
    background-color: var(--aux1);
}

.bg-aux2 {
    background-color: var(--aux2);
}

.bg-aux3 {
    background-color: var(--aux3);
}

.bg-aux4 {
    background-color: var(--aux4);
}

.bg-aux5 {
    background-color: var(--aux5);
}