.triangle,
.triangle-shadow
 {
    top: -282px;
    width: 0;
    height: 0;
    border-right: var(--tringulo-width) solid transparent;
    border-top: var(--tringulo-width) solid transparent;
    border-left: var(--tringulo-width) solid transparent;
    border-bottom: var(--tringulo-width) solid white;
    z-index: 1;
}

@media(min-width:640px) {
    :root {
        --tringulo-width: 160px;
    }

    .triangle,
    .triangle-shadow {
        top: -322px;
    }
}

.triangle-shadow {
    left: 39px;
    z-index: 0;
    border-bottom: var(--tringulo-width) solid black;
}

.triangle-shadow-left {
    right: 7px;
    left: 0;
    z-index: 0;
    border-bottom: var(--tringulo-width) solid black;
}

.triangle-shadow-top{
    left: 15px;
    top: -294px;
}


@media(min-width:640px) {
    .triangle-shadow-top {
        top: -334px;
    }
}