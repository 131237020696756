@import url("./__vars.css");


body {

    background-color: white;
    font-family: var(--font-text), Verdana, Geneva, Tahoma, sans-serif;
}

h1,h2,h3,h4,h5,h6{
    font-family: var(--font-title);
}

img.lil-nessian-display {
    max-width: 400px;
}

a.mx-1 img,
a.mx-2 img {
    border-radius: 50%;
    /* transition: box-shadow 0.25s ease-out; */
}



footer {
    position: sticky;
    top: 100vh;
}

@media (max-width: 1023px) {

    img.lil-nessian-display {
        width: 70%;
        max-width: none;
    }
}

/*
    MINT NFT BUTTON
*/

.loader-gif {
    width: 24px;
    height: 24px;
    display: inline-block;
}

.hidden-gif {
    display: none !important;
}

#mintNFT.bg-green-200:hover {
    cursor: default;
}


/* SCROLL BAR */

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 12px;
    background-color: white;
}

::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 10px;
    width: 5px;
    border: 3px solid white;
}

