@import url("./css/__vars.css");
@tailwind base;
@tailwind components;
@tailwind utilities;    


.center-absolute {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}