.reader {
    margin: 0 auto;
    max-width: 680px;
    background-color: #FEFEFE;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    line-height: 1.5;
}

.reader h1 {
    color: #000;
    font-family: Tahoma, sans-serif;
    text-shadow: none;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: none;  
}

.reader p {
    margin: revert;
}

img.logo {
    max-width: 300px;
}