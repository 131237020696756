@import "../__vars.css";

:root {
    --padding: 10px;
}

#tokenAmount {
    min-width: 71.5008px !important;
    outline-color: var(--primary);
    color: black !important;
    margin: 20px 0px 10px 0px;
}

